#pemeriksaan {
    margin-top: 60px;
    padding: 20px 30px;
    transition: all 0.3s;
}

@media (max-width: 1199px){
    #pemeriksaan {
        padding: 20px;
    }
}