#peserta {
    margin-top: 60px;
    padding: 20px 30px;
    transition: all 0.3s;
}

@media (max-width: 1199px){
    #peserta {
        padding: 20px;
    }
}

.btn {
    margin-top: 10px;
    margin-bottom: 30px;
}
