
.lupa-akun {
  top: 4rem;
  bottom: 0;
  right: 0;
  transform: translate(0, 100%);
}

.position-relative {
  position: relative;
}
