.sidebar {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    width: 300px;
    z-index: 996;
    transition: all 0.3s;
    padding: 20px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #aab7cf transparent;
    box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
}

/* Layar Mobile */
@media (max-width: 1199px) {
    .sidebar {
        left: -300px;
    }

    .toggle-sidebar .sidebar {
        left: 0;
    }
}

.sidebar::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: #aab7cf;
}

@media (min-width: 1200px) {
    #main,
    #peserta,
    #jadwal,
    #petugas,
    #pemeriksaan,
    #vitamin,
    #login,
    #tambah_peserta,
    #edit_peserta,
    #kesehatan_peserta,
    #tambah_petugas,
    #edit_petugas,
    #tambah_vitamin,
    #edit_vitamin,
    #tambah_jadwal,
    #edit_jadwal,
    #ubah_password,
    #tambah_pemeriksaan,
    #info_pemeriksaan,
    #pemberian_vitamin,
    #hasil_pemeriksaan,
    #konfirmasi_guest,
    #riwayat_peserta {
        margin-left: 300px;
    }

    .toggle-sidebar #main,
    .toggle-sidebar #peserta,
    .toggle-sidebar #jadwal,
    .toggle-sidebar #petugas,
    .toggle-sidebar #pemeriksaan,
    .toggle-sidebar #vitamin,
    .toggle-sidebar #login,
    .toggle-sidebar #tambah_peserta,
    .toggle-sidebar #edit_peserta,
    .toggle-sidebar #kesehatan_peserta,
    .toggle-sidebar #tambah_petugas,
    .toggle-sidebar #edit_petugas,
    .toggle-sidebar #tambah_vitamin,
    .toggle-sidebar #edit_vitamin,
    .toggle-sidebar #tambah_jadwal,
    .toggle-sidebar #edit_jadwal,
    .toggle-sidebar #tambah_pemeriksaan,
    .toggle-sidebar #pemberian_vitamin,
    .toggle-sidebar #info_pemeriksaan,
    .toggle-sidebar #hasil_pemeriksaan,
    .toggle-sidebar #ubah_password,
    .toggle-sidebar #konfirmasi_guest,
    .toggle-sidebar #riwayat_peserta {
        margin-left: 0;
    }

    .toggle-sidebar .sidebar {
        left: -300px;
    }
}

.sidebar-nav {
    padding: 0;
    margin: 0;
    list-style: none;
}

.sidebar-nav li {
    padding: 0;
    margin: 0;
    list-style: none;
}

.sidebar-nav .nav-heading {
    font-size: 11px;
    text-transform: uppercase;
    color: #899bbd;
    font-weight: 600;
    margin: 10px 0 5px 15px;
}

.sidebar-nav .nav-link {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    color: #4154f1;
    transition: 0.3;
    background: #f6f9ff;
    padding: 10px 15px;
    border-radius: 4px;
}

.sidebar-nav .nav-link i {
    font-size: 16px;
    margin-right: 10px;
    color: #4154f1;
}

.sidebar-nav .nav-link.collapsed {
    color: #012970;
    background: #fff;
}

.sidebar-nav .nav-link.collapsed i {
    color: #899bbd;
}

.sidebar-nav .nav-link:hover {
    color: #4154f1;
    background: #f6f9ff;
}

.sidebar-nav .nav-link:hover i {
    color: #4154f1;
}

.sidebar-nav .nav-link .bi-chevron-down {
    margin-right: 0;
    transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
    transform: rotate(180deg);
}

.sidebar-nav .nav-content {
    padding: 5px 0 0 0;
    margin: 0;
}

