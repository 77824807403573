@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root{
  scroll-behavior: smooth;
  --primary: #4154f1;
  --second: #717ff5;
  --bgColor: #f6f9ff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  font-family: 'Open Sans', sans-serif;
  color: #444444;
  background: var(--bgColor);
}

a {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: var(--second);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Nunito', sans-serif;
}

.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.card-header,
.card-footer{
  border-color: #ebeef4;
  background-color: #fff;
  color: #798eb3;
  padding: 15px;
}

.card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: #012970;
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
}